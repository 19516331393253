import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { apiRoute, commonHeaders } from "utils/api";

export default function usePrompts(setid?: string) {
  const { data, isFetching: isPromptsLoading } = useQuery({
    queryKey: ["usePrompts", setid],

    queryFn: () =>
      axios.get<any>(`${apiRoute}/prompt`, {
        params: {
          page: 1,
          limit: 10,
          setId: setid,
        },
        ...commonHeaders(),
      }),
  });

  const prompts = data?.data?.data;

  return { prompts, isPromptsLoading };
}
